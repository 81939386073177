import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from '../components/Login';
import UserDetail from '../components/UserDetail';


const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<LoginPage />} />
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/userdetail" element={<UserDetail />} />
        {/* <Route path="/register" element={<RegistrationForm />} />
        <Route path="/courses" element={<CourseList />} />
        <Route path="/courses/:courseId" element={<BatchesList />} />
        <Route path="/registration/complete/:enrollmentId" element={<ConfirmEnrollment />} /> */}
      </Routes>
    </Router>
  );
};

export default AppRouter;
