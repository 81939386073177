import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


// LOCAL
//const firebaseConfig = {
//    apiKey: "AIzaSyCqJE0ldfOChhN5vC-pxgVsOPX_1q9wi2w",
//    authDomain: "register-courses-17ca8.firebaseapp.com",
//    projectId: "register-courses-17ca8",
//    storageBucket: "register-courses-17ca8.appspot.com",
//    messagingSenderId: "931449092785",
//    appId: "1:931449092785:web:85b512074938bffdba0090"
//};


// LIVE
// const firebaseConfig = {
//     apiKey: "AIzaSyCsgqdYDmI9xoZN1NcrSdrWwiYwbecmkAE",
//     authDomain: "astc-registration-23.firebaseapp.com",
//     projectId: "astc-registration-23",
//     storageBucket: "astc-registration-23.appspot.com",
//     messagingSenderId: "325741495334",
//     appId: "1:325741495334:web:78d0799c71766c06d0d2e7"
// };

// LIVE 24
const firebaseConfig = {
     apiKey: "AIzaSyB-fnVmStngbP06gYY9z-DihXmr89uu20A",
     authDomain: "astc-registration-24.firebaseapp.com",
     projectId: "astc-registration-24",
     storageBucket: "astc-registration-24.appspot.com",
     messagingSenderId: "944535077505",
     appId: "1:944535077505:web:8dea2f3eb62a7c73ee4380"
   };

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
