import React, { useEffect, useState } from 'react';
import { auth, firestore } from '../firebase';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import '../index.css';
// import '../css/LoginForm.css';
import { Link, useNavigate } from 'react-router-dom';
import QRReader from '../components/QrScanner';
import { Scanner } from '@yudiel/react-qr-scanner';
import 'bootstrap/dist/css/bootstrap.css';
import { set } from 'firebase/database';


const UserDetail = () => {
    const [enrollmentId, setEnrollmentId] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [userId, setUserId] = useState('');
    const [user, setUser] = useState('');

    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const [enrollments, setEnrollments] = useState([]);




    const fetchUserData = async (customUserId) => {
        console.log("UserID");
        console.log(userId);

        const currentUserId = customUserId ? customUserId : userId;

        console.log({ currentUserId })

        try {
            const querySnapshot = await firestore.collection('enrollments').where('enrolledBy', '==', currentUserId).get();
            if (!querySnapshot.empty) {
                console.log(querySnapshot.docs);
                const enrollments = querySnapshot.docs.map(doc => doc.data());
                console.log('Enrollments:', enrollments);
                setEnrollments(enrollments);
                //return enrollments;
            } else {
                console.log('No enrollments found for this user.');
                //return [];
            }
            const userQuerySnapshot = await firestore.collection('users').doc(currentUserId).get().then((doc) => {
                if (doc.exists) {
                    const userDoc = doc.data();
                    console.log(doc);
                    console.log('USER ID:', userDoc.id);
                    setUser(userDoc);
                }
            });

        } catch (error) {
            console.error('Error getting enrollment documents:', error);
            return [];
        }
    };

    const findUserFromEmail = async () => {
        console.log("Email");
        console.log(email);

        setUserId('')
        setUser('')
        setPhoneNumber('')
        setEnrollments([])


        try {
            const querySnapshot = await firestore.collection('users').where('email', '==', email).get();
            if (!querySnapshot.empty) {
                const userDoc = querySnapshot.docs[0];
                console.log('Document ID:', userDoc.id);
                setUser(userDoc.data());
                // console.log("Current User");
                // console.log(userDoc.data());
                setUserId(userDoc.id);
                fetchUserData(userDoc.id);

                return userDoc.id;
            } else {
                console.log('No user found with this email.');
                alert("No user found with this email.")
                return null;
            }
        } catch (error) {
            console.error('Error getting user document:', error);
            return null;
        }
    };

    const findUserFromPhone = async () => {
        console.log("phoneNumber");
        console.log(phoneNumber);

        setUserId('')
        setUser('')
        setEmail('')
        setEnrollments([])


        try {
            const querySnapshot = await firestore.collection('users').where('contact', '==', phoneNumber).get();
            if (!querySnapshot.empty) {
                const userDoc = querySnapshot.docs[0];
                console.log('Document ID:', userDoc.id);
                setUserId(userDoc.id);
                setUser(userDoc.data());
                fetchUserData(userDoc.id);

                return userDoc.id;
            } else {
                console.log('No user found with this phone number.');
                alert("No user found with this phone number.")
                return null;
            }
        } catch (error) {
            console.error('Error getting user document:', error);
            return null;
        }
    };

    const findUserFromEnrollmentID = async (customEnrollmentId) => {
        console.log("enrollmentId");
        console.log(enrollmentId);

        const currentEnrollmentId = customEnrollmentId ? customEnrollmentId : enrollmentId;

        console.log({ currentEnrollmentId });

        try {
            const querySnapshot = await firestore.collection('enrollments').doc(currentEnrollmentId).get().then((doc) => {
                if (doc.exists) {
                    console.log(doc);
                    const userData = doc.data();
                    const _userId = userData.enrolledBy;
                    console.log(userData);
                    console.log(_userId);
                    setUserId(_userId);
                    fetchUserData(_userId);
                }
            });
        } catch (error) {
            console.error('Error getting user', error);
            return null;
        }
    };

    const markAsPaid = async (batchID, enrollmentIndex, batchIndex, status) => {
        console.log("batchID");
        console.log(batchID);
        console.log("userID");
        console.log(userId);
        console.log("enrollmentIndex");
        console.log(enrollmentIndex);
        console.log("batchIndex");
        console.log(batchIndex);
        console.log(enrollments);
        // try {
        //     const enrollmentRef = doc(firestore, 'enrollments', enrollmentId);
        //     await updateDoc(enrollmentRef, { status: 'Paid' });
        //     setEnrollments(enrollments.map(enrollment =>
        //         enrollment.id === enrollmentId ? { ...enrollment, status: 'Paid' } : enrollment
        //     ));
        // } catch (error) {
        //     console.error('Error marking as paid:', error);
        // }
        try {
            const q = query(collection(firestore, 'enrollments'), where('enrolledBy', '==', userId));
            const querySnapshot = await getDocs(q);
            console.log("querySnapshot");
            console.log(querySnapshot);
            querySnapshot.forEach(docs => {
                const enrollment = docs.data();
                console.log("enrollment");

                const batchIndex = enrollment.batches.findIndex(batch => batch.batchId === batchID);
                console.log("batchIndex");
                console.log(batchIndex);
                if (batchIndex !== -1) {
                    enrollment.batches[batchIndex].status = status;
                    const enrollmentRef = doc(firestore, 'enrollments', docs.id);
                    updateDoc(enrollmentRef, { batches: enrollment.batches }).then(() => {
                        console.log("updated");
                        console.log(enrollments[enrollmentIndex].batches[batchIndex]);
                        enrollments[enrollmentIndex].batches[batchIndex].status = status;
                        // var temp = enrollments;
                        // enrollment = temp;
                        setEnrollments([...enrollments]);
                    });

                }
            });
        } catch (error) {
            console.error('Error marking as paid:', error);
        }
    };

    //   useEffect(() => {
    //     if(window.location.host != "registeration-react-app.pages.dev" && window.location.host != 'localhost:3000') {
    //       const displayNotice = () => { 
    //         var elemDiv = document.createElement("div");
    //         elemDiv.style.cssText = "text-align: center;top:0;left:0;bottom:0;right:0;position: fixed;background-color: white;z-index:99999;overflow: hidden;display: flex;text-align: center;align-items: center;font-size: 1.75rem;color:#000;justify-content: center;font-weight: 600;";
    //         elemDiv.innerHTML = "<div style='padding: 20px 45px;color: #000;border-radius: 12px;font-size: 1.75rem;background: #fff;box-shadow: 0 2px 10px #ddd;'><div><img src='https://gocbe-lms-astc.b-cdn.net/assets/astc-registration-2024-25-banner-compressed.jpg' alt='image' height='350'></div></div>"; 
    //         document.body.appendChild(elemDiv); 
    //       }     
    //       var timeAt12pm = new Date("06/05/2024 11:59:59 AM")
    //       var timeNow = new Date();

    //       if(timeNow.getTime() < timeAt12pm.getTime()){
    //         //setTimeout(displayNotice, 200);
    //       }
    //     }

    //     auth.onAuthStateChanged((user) => {
    //       if (user) {
    //         navigate('/courses');
    //       }
    //     });
    //   });

    return (
        <div className="login-body form-control">
            {/* <div className="logo">
                <img src="https://gocbe-lms-astc.b-cdn.net/assets/Logo/Image/7514e432-bd05-4d34-a7ed-3697c150a1d1logo-1.jpg" />
            </div> */}
            <div className="login-form-container">
                {error && <div className="error-message">{error.replace("Firebase: ", "")}</div>}

                <div id="qr-scanner">
                    <Scanner onScan={(result) => {
                        console.log(result[0].rawValue)
                        setEmail('')
                        setUser('')
                        setEnrollmentId(result[0].rawValue)
                        // console.log('ahmed:' + enrollmentId)
                        findUserFromEnrollmentID(result[0].rawValue)
                    }} />
                </div>
                {/* <div>
                    <label>
                        Enrollment ID:
                    </label>
                    <input
                        type="text"
                        value={enrollmentId}
                        onChange={(e) => setEnrollmentId(e.target.value)}
                    />
                    <button onClick={findUserFromEnrollmentID}>Find UserID</button>
                </div> */}

                <div className="form-group">
                    <label>
                        User ID: <label>{userId}</label>
                        {/* <input
                            type="text"
                            readOnly="true"
                            value={userId}
                            onChange={(e) => setUserId(e.target.value)}
                        /> */}



                        {/* <button type="submit" className="custom-btn" onClick={fetchUserData} disabled={isLoading}>{isLoading ? "Please wait..." : "Find"}</button> */}
                        {/* <span> {userId}</span> */}
                    </label>
                </div>
                <div>OR</div>
                <div>
                    <label>
                        Email:
                    </label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <button onClick={findUserFromEmail}>Search</button>
                </div>
                <div>
                    <label>
                        Phone Number:
                    </label>
                    <input
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <button onClick={findUserFromPhone}>Search</button>
                </div>
                {/* <div className="form-group">  console.log(enrollment);
                    <button type="submit" className="custom-btn" onClick={fetchUserData} disabled={isLoading}>{isLoading ? "Please wait..." : "Find"}</button>
                </div> */}
                {/* <div className="signup-link">
        Don't have an account?{' '}
        <br/>
        <br/>
        <Link to="/register" className="custom-btn">Create New Account to Register</Link>
      </div> */}
            </div>

            {/* <button className="add-json-data-btn" onClick={()=>addDataToFirestore(jsonData)}>Add Data</button> */}

            {enrollments && enrollments.length > 0 ?
                <div>
                    <div className="Enrollments">
                        <br />
                        <h4>{user?.firstName} {user?.lastName} ({user?.email})</h4>
                        <br />
                        <div className="enrollments-container">
                            <table className='table table-bordered'>
                                <thead>
                                    <tr>
                                        <th>Course</th>
                                        <th>Batch</th>
                                        <th>Trainer</th>
                                        <th>Price</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {enrollments.map((enrollment, enrollmentIndex) => (
                                        enrollment.batches.map((batch, batchIndex) => (
                                            <tr key={`${enrollmentIndex}-${batchIndex}`}>
                                                <td>{batch.courseTitle}</td>
                                                <td>{batch.batchTitle}</td>
                                                <td>{batch.trainer}</td>
                                                <td>{batch.price}</td>
                                                <td>
                                                    {batch.status === 'Paid' ? <strong className='text-success'>Paid</strong> : <strong className='text-danger'>Unpaid</strong>}
                                                </td>
                                                <td>
                                                    {batch.status === 'Paid' ? (
                                                        <button className='btn btn-sm btn-warning' onClick={() => markAsPaid(batch.batchId, enrollmentIndex, batchIndex, "Unpaid")}>Mark as Unpaid</button>

                                                    ) : (
                                                        <button className='btn btn-sm btn-success' onClick={() => markAsPaid(batch.batchId, enrollmentIndex, batchIndex, "Paid")}>Mark as Paid</button>

                                                    )}
                                                </td>
                                            </tr>
                                        ))
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> : <div></div>}

        </div>


    );
};

export default UserDetail;
